<template>
  <transition appear>
    <create-user />
  </transition>
</template>

<script>
import createUser from "@/components/ui/baseComponents/Recruitment/createUser.vue";
export default {
  components: {
    createUser,
  },
};
</script>
